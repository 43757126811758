import { Button, Row, Card, Collapse, Space } from 'antd';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { useRef, useState } from 'react';
import './create-incident-report.scss';
import {
  IIncidentAttachment,
  IIncidentInvolvedPerson,
  IIncidentInvolvedVehicle,
  IIncidentReportRequest,
} from 'common/services/incident-report/incident-report.types';
import { notification } from 'common/utils/notification';
import IncidentInvolvedPersons from '../../../../common/components/incident-report/incident-involved-persons/incident-involved-persons';
import IncidentInvolvedVehicles from '../../../../common/components/incident-report/incident-involved-vehicles/incident-involved-vehicles';
import { IncidentAttachment } from '../../../../common/components/incident-report/incident-attachment/incident-attachment';

import './create-incident-report.scss';
import dayjs from 'dayjs';
import incidentReportService from 'common/services/incident-report/incident-report.service';
import { useNavigate } from 'react-router-dom';
import { IReportingPersonFormValues } from '../../../../common/components/incident-report/incident-reporting-person/incident-reporting-person-edit/incident-reporting-person-edit.types';
import { IncidentReportingPerson } from '../../../../common/components/incident-report/incident-reporting-person/incident-reporting-person';
import { IIncidentLocationFormValues } from '../../../../common/components/incident-report/incident-location/incident-location-edit/incident-location-edit.types';
import IncidentLocation from '../../../../common/components/incident-report/incident-location/incident-location';
import { IIncidentDetailsFormValues } from '../../../../common/components/incident-report/incident-details/incident-details-edit/incident-details-edit.types';
import IncidentDetails from '../../../../common/components/incident-report/incident-details/incident-details';
import {
  ATTACHMENT_SECTION_NAME,
  DETAILS_SECTION_NAME,
  INVOLVED_PEOPLE_SECTION_NAME,
  INVOLVED_VEHICLES_SECTION_NAME,
  LOCATION_SECTION_NAME,
  REPORTING_PERSON_SECTION_NAME,
} from 'common/constans/incident-report.constants';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';

export const CreateIncidentReport = () => {
  const navigate = useNavigate();
  const { isMobile, isNotMobile } = useMedia();
  const [incidentReport, setIncidentReport] = useState<IIncidentReportRequest>({
    reportingPerson: '',
    description: '',
    gate: '',
    addressId: 0,
    incidentDate: 0,
    category: '',
    weatherCondition: '',
    isEmsResponseRequired: false,
    isFireDepartmentResponseRequired: false,
    isPoliceDepartmentResponseRequired: false,
    involvedPersons: [],
    involvedVehicles: [],
    attachments: [],
  });

  const [activeItemIndex, setActiveItemIndex] = useState(1);

  const handleIncidentDetailsChange = (values: IIncidentDetailsFormValues) => {
    setIncidentReport((prev) => {
      return {
        ...prev,
        category: values.category,
        subCategory: values.subCategory,
        weatherCondition: values.weatherCondition,
        isEmsResponseRequired: values.isEmsResponseRequired,
        isFireDepartmentResponseRequired: values.isFireDepartmentResponseRequired,
        isPoliceDepartmentResponseRequired: values.isPoliceDepartmentResponseRequired,
        description: values.description,
      };
    });
  };

  const handleIncidentLocationChange = (values: IIncidentLocationFormValues) => {
    setIncidentReport((prev) => {
      return {
        ...prev,
        gate: values.gate,
        addressId: values?.address?.addressId,
        incidentDate: dayjs(values.incidentDate).unix(),
      };
    });
  };

  const handleReportingPersonChange = (values: IReportingPersonFormValues) => {
    setIncidentReport((prev) => ({ ...prev, reportingPerson: values.reportingPerson }));
  };

  const handleInvolvedPeopleChange = (values: IIncidentInvolvedPerson[]) => {
    setIncidentReport((prev) => ({ ...prev, involvedPersons: values }));
  };

  const handleInvolvedVehiclesChange = (values: IIncidentInvolvedVehicle[]) => {
    setIncidentReport((prev) => ({ ...prev, involvedVehicles: values }));
  };

  const handleAttachmentChange = (values: IIncidentAttachment[]) => {
    setIncidentReport((prev) => ({ ...prev, attachments: values }));
  };

  const getStepClassName = (key: number) => {
    return activeItemIndex > key ? 'step-header-completed' : '';
  };

  const formRefs: { [key: number]: React.MutableRefObject<any> } = {
    1: useRef(),
    2: useRef(),
    3: useRef(),
  };

  const handleSave = () => {
    if (!incidentReport) {
      return;
    }

    incidentReportService.createIncident(incidentReport).then((caseNumber: number) => {
      notification.success({ message: `Incident Report Created with Case Number: ${caseNumber}` });
      navigate('/eiq-secure/incident-reports');
    });
  };

  const items = [
    {
      key: 1,
      className: getStepClassName(1),
      label: REPORTING_PERSON_SECTION_NAME,
      children: <IncidentReportingPerson onSave={handleReportingPersonChange} ref={formRefs[1]} />,
    },
    {
      key: 2,
      className: getStepClassName(2),
      label: LOCATION_SECTION_NAME,
      children: <IncidentLocation onSave={handleIncidentLocationChange} ref={formRefs[2]} />,
    },
    {
      key: 3,
      className: getStepClassName(3),
      label: DETAILS_SECTION_NAME,
      children: <IncidentDetails onSave={handleIncidentDetailsChange} ref={formRefs[3]} />,
    },
    {
      key: 4,
      className: getStepClassName(4),
      label: INVOLVED_PEOPLE_SECTION_NAME,
      children: <IncidentInvolvedPersons onChange={handleInvolvedPeopleChange} />,
    },
    {
      key: 5,
      className: getStepClassName(5),
      label: INVOLVED_VEHICLES_SECTION_NAME,
      children: <IncidentInvolvedVehicles onChange={handleInvolvedVehiclesChange} />,
    },
    {
      key: 6,
      className: getStepClassName(6),
      label: ATTACHMENT_SECTION_NAME,
      children: <IncidentAttachment onChange={handleAttachmentChange} />,
    },
  ];

  const handleCollapseChange = (key: string | string[]) => {
    const activeKey = Array.isArray(key) ? parseInt(key[1], 10) : parseInt(key, 10);
    if (activeKey <= activeItemIndex) {
      setActiveItemIndex(activeKey);
      return false;
    }

    return true;
  };

  const handleNextClick = async () => {
    const currentForm = formRefs[activeItemIndex];

    if (activeItemIndex === 4) {
      if (incidentReport.involvedPersons.length === 0) {
        notification.error({ message: 'Please add at least one person involved' });
        return;
      }
    }

    if (activeItemIndex === 5) {
      if (incidentReport.involvedVehicles.length === 0) {
        notification.error({ message: 'Please add at least one vehicle involved' });
        return;
      }
    }

    if (activeItemIndex === items.length) {
      handleSave();
      return;
    }

    if (!currentForm) {
      setActiveItemIndex(activeItemIndex + 1);
      return;
    }

    currentForm?.current
      .submitForm()
      .then(async () => {
        notification.destroy();
        setActiveItemIndex(activeItemIndex + 1);
      })
      .catch((error: any) => {
        const { errorFields } = error;
        errorFields.forEach((field: any) => {
          notification.error({
            key: field.name.join('.'),
            duration: null,
            message: field.errors[0],
          });
        });
      });
  };

  return (
    <div className="eiq-secure-incident-report-page create">
      <Card className="eiq-card eiq-card-border" style={{ width: '100%' }}>
        <Row justify="space-between">
          <Space size={12}>
            <h2 className="main-title create-report-title">Create Report</h2>
          </Space>
          {isNotMobile && (
            <Space>
              <Button key="cancel" type="link" onClick={() => navigate('/eiq-secure/incident-reports')}>
                Cancel
              </Button>
              <Button type="primary" icon={<ArrowRight />} onClick={handleNextClick}>
                {activeItemIndex === items.length ? 'Save' : 'Next'}
              </Button>
            </Space>
          )}
        </Row>
        <div className="collapse-container">
          <Collapse
            defaultActiveKey={1}
            items={items}
            activeKey={activeItemIndex}
            onChange={handleCollapseChange}
            expandIconPosition="end"
            size="large"
          />
        </div>
        {isMobile && (
          <Space className="full-width">
            <Button className="full-width" key="cancel" type="link" onClick={() => navigate('/eiq-secure/incident-reports')}>
              Cancel
            </Button>
            <Button type="primary" className=" full-width css-primary-brn" icon={<ArrowRight />} onClick={handleNextClick}>
              {activeItemIndex === items.length ? 'Save' : 'Next'}
            </Button>
          </Space>
        )}
      </Card>
    </div>
  );
};
