import { Row, Col, Space } from 'antd';
import InfoField from 'common/components/info-block/info-field/info-field';
import { IIncidentInvolvedPersonViewProps } from './incident-involved-person-view.types';
import React from 'react';
import IncidentViewModeActions from '../../incident-view-mode-actions/incident-view-mode-actions';

export const IncidentInvolvedPersonView = ({ person, onDelete, onEdit }: IIncidentInvolvedPersonViewProps) => {
  return (
    <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', padding: '16px' }}>
      <Row gutter={16}>
        <Col xs={24} lg={8}>
          <Space direction="vertical" className="full-width">
            <InfoField value={person.firstName} label="First Name" />
            <InfoField value={person.lastName} label="Last Name" />
            <InfoField value={person.involvementType} label="Type of involvement" />
          </Space>
        </Col>
        <Col xs={24} lg={8}>
          <Space direction="vertical" className="full-width">
            <InfoField value={person?.phoneNumber} label="Phone number" />
            <InfoField value={`${person?.streetNumber}`} label="Street Number" />
          </Space>
        </Col>
        <Col xs={24} lg={8}>
          <Space direction="vertical" className="full-width">
            <InfoField value={person?.streetName} label="Street Name" />
            <InfoField value={`${person?.address || ''}`} label="Address Details" />
          </Space>
        </Col>
      </Row>
      <IncidentViewModeActions onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
};
