import { Col, DatePicker, Form, Row, TimePicker } from 'antd';
import { TIME_h_mma } from 'common/constans/date.constants';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useEffect } from 'react';
import { IIncidentDateFieldProps } from './incident-date-field.types';

const IncidentDateField = ({ initialValue }: IIncidentDateFieldProps) => {
  const [date, setDate] = useState<Dayjs | null>(initialValue ? dayjs(initialValue) : dayjs());
  const [time, setTime] = useState<Dayjs | null>(initialValue ? dayjs(initialValue) : dayjs());
  const form = Form.useFormInstance();

  useEffect(() => {
    if (date && time) {
      const combinedDateTime = date.hour(time.hour()).minute(time.minute()).second(0);
      form.setFieldsValue({ incidentDate: combinedDateTime });
    }
  }, [date, time, form]);

  const handleDateChange = (newDate: Dayjs | null) => {
    setDate(newDate);
  };

  const handleTimeChange = (newTime: Dayjs | null) => {
    setTime(newTime);
  };

  const disableFutureDates = (current: Dayjs) => {
    return current && current.endOf('day').isAfter(dayjs(), 'day');
  };

  const disableFutureTimes = () => {
    if (!date) {
      return {};
    }

    const now = dayjs();
    if (date.isSame(now, 'day')) {
      return {
        disabledHours: () => {
          const currentHour = now.hour();
          return Array.from({ length: 24 }, (_, i) => i).filter((hour) => hour > currentHour);
        },
        disabledMinutes: (selectedHour: number) => {
          const currentHour = now.hour();
          const currentMinute = now.minute();
          if (selectedHour === currentHour) {
            return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute > currentMinute);
          }
          return [];
        },
      };
    }

    return {};
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item label="Date">
          <DatePicker
            allowClear={false}
            format="MM/DD/YYYY"
            placeholder="Select date"
            style={{ width: '100%' }}
            value={date}
            onChange={handleDateChange}
            disabledDate={disableFutureDates}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Time">
          <TimePicker
            format={TIME_h_mma}
            placeholder="Select time"
            style={{ width: '100%' }}
            minuteStep={1}
            allowClear={false}
            use12Hours
            value={time}
            onChange={handleTimeChange}
            disabledTime={disableFutureTimes}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default IncidentDateField;
