import { useState } from 'react';
import { IInvolvedItem, IUseIncidentInvolvedFormProps } from './use-incident-involved-form.types';
import { notification } from 'common/utils/notification';

export function useIncidentInvolvedForm<TItem, TSearchItem>({ initialValues, onChange }: IUseIncidentInvolvedFormProps<TItem>) {
  const [items, setItems] = useState<IInvolvedItem<TItem>[]>(initialValues.map((item) => ({ item, mode: 'view' })));
  const [addMode, setAddMode] = useState<'search' | 'manual' | null>(!initialValues?.length ? 'search' : null);
  const [currentSelectedItem, setCurrentSelectedItem] = useState<TSearchItem | null>(null);

  const switchToSearchMode = () => setAddMode('search');

  const switchToManualMode = (selectedItem: TSearchItem | null) => {
    setCurrentSelectedItem(selectedItem);
    setAddMode('manual');
  };

  const cancelAddMode = () => setAddMode(null);

  const handleSelectedItem = (selectedItem: TSearchItem) => {
    setCurrentSelectedItem(selectedItem);
    switchToManualMode(selectedItem);
  };

  const updateItems = (newItems: IInvolvedItem<TItem>[]) => {
    setItems(newItems);
    onChange(newItems.map((item) => item.item));
  };

  const handleAdd = (itemData: TItem) => {
    const updatedItems: IInvolvedItem<TItem>[] = [...items, { item: itemData, mode: 'view' }];
    updateItems(updatedItems);
    cancelAddMode();
  };

  const handleSaveEdit = (index: number, itemData: TItem) => {
    const updatedItems: IInvolvedItem<TItem>[] = items.map((item, i) => (i === index ? { item: itemData, mode: 'view' } : item));
    updateItems(updatedItems);
  };

  const changeItemMode = (index: number, mode: 'edit' | 'view') => {
    const updatedItems = items.map((item, i) => (i === index ? { ...item, mode } : item));
    setItems(updatedItems);
  };

  const deleteItem = (index: number) => {
    if (items.length === 1) {
      notification.error({
        message: `At least 1 item is required. Please add another item before deleting this one.`,
      });
      return;
    }
    const updatedItems = items.filter((_, i) => i !== index);
    updateItems(updatedItems);
  };

  return {
    items,
    addMode,
    currentSelectedItem,
    switchToSearchMode,
    switchToManualMode,
    cancelAddMode,
    handleSelectedItem,
    handleAdd,
    handleSaveEdit,
    changeItemMode,
    deleteItem,
  };
}
