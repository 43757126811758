import { IIncidentReportRead } from 'common/services/incident-report/incident-report.types';
import { IIncidentLocationEditProps, IIncidentLocationFormValues } from './incident-location-edit.types';
import { Col, Form, Row, Select } from 'antd';
import configurationStore from 'common/stores/configuration/configuration.store';
import { useMemo } from 'react';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import IncidentEditModeActions from '../../incident-edit-mode-actions/incident-edit-mode-actions';
import { useAddressSelect } from 'common/hooks/use-address-select/use-address-select.hook';
import { incidentLocationFormRules } from './incident-location-edit.validation';
import IncidentDateField from '../../incident-date-field/incident-date-field';
import { IAddress } from 'common/services/street-service/street.service.types';

export const convertToFormValues = (incident: IIncidentReportRead | undefined): IIncidentLocationFormValues | undefined => {
  if (!incident) {
    return undefined;
  }

  return {
    incidentDate: incident.incidentDate,
    gate: incident.gate,
    address: {
      streetName: incident.address?.street?.name || '',
      streetNumber: incident.address?.streetNumber || '',
      addressId: incident.address?.id || 0,
    },
  };
};

const IncidentLocationEdit = ({ incident, onSave, onCancel, form }: IIncidentLocationEditProps) => {
  const gates = useMemo(() => configurationStore.configuration?.gates || [], []);
  const gatesOptions = gates.map((gate) => ({ label: gate.name, value: gate.name }));

  const defaultGate = !incident ? gatesOptions[0] : undefined;

  const addressSelect = useAddressSelect({
    value: undefined,
    onAddressChange: (value: IAddress | null) => {
      console.log('address', value);
      form.setFieldValue(['address', 'addressId'], value?.id);
    },
    isOpen: true,
    initData: incident?.address,
    form: form,
  });

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleSave = (values: IIncidentLocationFormValues) => {
    console.log('values', values);
    console.log('form values', form.getFieldsValue());
    onSave(values);
  };

  return (
    <FormWrapper form={form} autoComplete="false" layout="vertical" onFinish={handleSave} initialValues={convertToFormValues(incident)}>
      <Row gutter={{ lg: 16, md: 8, xs: 0 }}>
        <Col xs={24} lg={8}>
          <Form.Item name="incidentDate" className="incidentDateItem">
            <IncidentDateField initialValue={incident?.incidentDate ? new Date(incident?.incidentDate * 1000) : undefined} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          {addressSelect.StreetSelect}
        </Col>
        <Col xs={24} lg={8}>
          {addressSelect.AddressDetailsSelect}
        </Col>
      </Row>
      <Row gutter={{ lg: 16, md: 8, xs: 0 }}>
        <Col xs={24} lg={8}>
          <Form.Item name="gate" label="Gate" rules={incidentLocationFormRules.gate} initialValue={defaultGate?.value}>
            <Select placeholder="Select Gate" options={gatesOptions} defaultValue={defaultGate} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          {addressSelect.StreetNumberSelect}
        </Col>
      </Row>
      {incident && <IncidentEditModeActions onCancel={handleCancel} />}
    </FormWrapper>
  );
};

export default IncidentLocationEdit;
