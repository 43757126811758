import { notification, Space, AutoComplete, Input, Button, Row, Col } from 'antd';
import residentsService from 'common/services/residents-service/residents.service';
import { IResidentAddressRead, IResidentAddressListResponse } from 'common/services/residents-service/residents.service.types';
import React, { useState } from 'react';
import { IIncidentInvolvedPersonSearchProps } from './incident-involved-person-search.types';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';

export const IncidentInvolvedPersonSearch = ({ onSelected, onEnterManauallyClick }: IIncidentInvolvedPersonSearchProps) => {
  const [residents, setResidents] = useState<IResidentAddressRead[]>([]);

  const buildSearchQuery = (search?: string) => {
    if (!search) {
      return null;
    }

    return `name:${search}* OR address:${search}* OR address_blockNumber:${search}* OR address_buildingNumber:${search}*`;
  };

  const handleSearch = (newValue?: string) => {
    if (!newValue) {
      setResidents([]);
      return;
    }

    residentsService
      .getResidentAddresses(null, buildSearchQuery(newValue), null)
      .then((response: IResidentAddressListResponse) => setResidents(response.items))
      .catch((e) => {
        notification.error({
          message: 'Can not fetch residents',
        });
        console.log(e);
      });
  };

  const handleSelect = (id: string) => {
    const selectedItem = residents.find((item) => item.id.toString() === id);

    if (selectedItem) {
      onSelected(selectedItem);
    }
  };

  return (
    <>
      <Space className="full-width" size={13}>
        <Row gutter={{ xs: 24, md: 8 }}>
          <Col xs={24} lg={8}>
            <AutoComplete
              className="full-width"
              defaultActiveFirstOption={false}
              onSearch={handleSearch}
              onChange={handleSelect}
              dataSource={residents.map((item) => ({
                text: `${item.resident.firstName} ${item.resident.lastName}`,
                value: item.id.toString(),
              }))}>
              <Input placeholder="Search by resident name, address" prefix={<SearchIcon />} />
            </AutoComplete>
          </Col>
        </Row>
      </Space>
      <Space className="full-width" size={13}>
        <p className="manual-info-add-label">
          If it is a guest, or resident is not found in EIQ. Please fill in personal information manually.
        </p>
      </Space>

      <Space>
        <Button type="primary" className="primary-btn" icon={<AdmitIcon />} onClick={onEnterManauallyClick}>
          Enter Manually
        </Button>
      </Space>
    </>
  );
};
